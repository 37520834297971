const firebaseConfig = {
  apiKey: 'AIzaSyBmC3nbM2qWlZ0pFn6QM1qgJqRAlSmkdPc',
  authDomain: 'eskda-d909b.firebaseapp.com',
  databaseURL: 'https://eskda-d909b-default-rtdb.firebaseio.com',
  projectId: 'eskda-d909b',
  storageBucket: 'eskda-d909b.appspot.com',
  messagingSenderId: '137587667702',
  appId: '1:137587667702:web:06a2e0c0ca7f37bc1e32d7',
};
export default firebaseConfig;
