import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    outline: 0;
    font-family: Roboto, sans-serif;
    text-decoration: none;
  }

  body {
    background-color: #0c2a46; 
  }

  button {
    cursor: pointer;
  }

::-webkit-scrollbar {
  width: 2.5px;
}
::-webkit-scrollbar-track {
  background: #0c2a46;
}
::-webkit-scrollbar-thumb {
  background: #00c9c4;
  height: 10px;
::-webkit-scrollbar-thumb:hover {
  background: #00a0a4;
}
`;

export default GlobalStyle;
