import { use100vh } from 'react-div-100vh';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: #0c2a46;
  min-height: ${use100vh}px;
  overflow: auto;

  > div:first-child {
    div {
      button {
        background: transparent;
      }
    }

    @media (max-width: 450px) {
      button {
        width: 160px;

        span {
          display: none;
        }
      }
    }
  }
`;

export const Content = styled.div`
  max-width: 1024px;
  margin: 5% auto;

  img {
    width: ${(500 / window.innerWidth) * 100}vw;
    @media (max-width: 500px) {
      width: ${(320 / window.innerWidth) * 100}vw;
    }
  }
  @media (max-width: 1024px) {
    margin: 0 20px;
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    ul {
      width: 100%;

      li {
        padding: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #ffff;
        height: 38px;
        border: 1px solid #00c9c4;
        display: flex;
        background-color: #0a223a;
        font-weight: 500;
        border-radius: 8px;
        & + li {
          margin-top: 8px;
        }
        div {
          button {
            background: transparent;
            color: #ffff;
            & + button {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  strong {
    color: #00c9c4;
    font-size: 48px;
  }
  span {
    color: #ffff;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
`;

export const DeleteModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);

  > div {
    margin: 10% auto;
    width: 50%;
    @media (max-widthk: 1024px) {
      width: 85%;
    }
  }
`;

export const DeleteModalHeader = styled.div`
  background-color: #9c1b1b;
  color: #ffff;
  border-radius: 16px 16px 0 0;
  padding: 20px;

  > span {
    margin-top: 20px;
    display: block;
  }
  > div {
    display: flex;
    align-items: center;

    strong {
      margin-left: 10px;
      font-size: 20px;
    }
  }
`;

export const DeleteModalContent = styled.div`
  background-color: #0c2a46;
  padding: 20px;
  color: #fff;
  border-radius: 0 0 16px 16px;

  form {
    margin-top: 20px;

    div {
      width: 100%;
    }

    section {
      display: flex;
      margin-top: 30px;
      justify-content: flex-end;
      button {
        width: 120px;
        float: right;

        & + button {
          margin-top: 0;
          margin-left: 15px;
        }
      }
    }
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${use100vh}px;
`;
