import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import firebase from 'firebase';
import { auth } from '../firebase/firebase';

interface SignInProps {
  email: string;
  password: string;
}

interface AuthContextData {
  signIn(props: SignInProps): Promise<void>;
  signOut(): Promise<void>;
  user: firebase.User | null;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<firebase.User | null>(() => {
    const userData = localStorage.getItem('@Firebase-tests:user');
    if (userData !== 'null' && !!userData) {
      return JSON.parse(userData);
    }
    return null;
  });

  useEffect(() => {
    auth.onAuthStateChanged(userAuth => {
      localStorage.setItem('@Firebase-tests:user', JSON.stringify(userAuth));
      setUser(userAuth);
    });
  }, []);

  const signIn = useCallback(async (props: SignInProps) => {
    const { user: userData } = await auth.signInWithEmailAndPassword(
      props.email,
      props.password,
    );
  }, []);

  const signOut = useCallback(async () => {
    await auth.signOut();
  }, []);

  return (
    <AuthContext.Provider value={{ signIn, signOut, user }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);
  if (!context) {
    throw Error('useAuth error');
  }
  return context;
}
