import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  FiAlertTriangle,
  FiEdit2,
  FiPlus,
  FiPower,
  FiTrash2,
} from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import ReactLoading from 'react-loading';

import { stringify } from 'querystring';
import Header from '../../components/Header';
import {
  Container,
  Content,
  DeleteModal,
  DeleteModalHeader,
  DeleteModalContent,
  LoadingContainer,
} from './styles';
import emptyImg from '../../assets/empty-image.svg';
import Button from '../../components/Button';
import { firestore } from '../../firebase/firebase';
import Client from '../../types/Client';
import Input from '../../components/Input';
import { useToast } from '../../hooks/Toast';
import { useAuth } from '../../hooks/Auth';

interface SyntheticEvent<T> {
  currentTarget: EventTarget & T;
}

const Dashboard: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const { signOut } = useAuth();

  const [clients, setClients] = useState<Client[]>([]);
  const [deleteState, setDeleteState] = useState(false);
  const [clientToDelete, setClientToDelete] = useState<Client | null>(null);
  const [loading, setLoading] = useState(true);

  const handleFormDeleteCancel = useCallback(() => {
    setClientToDelete(null);
  }, []);

  const handleClientDeleteAction = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      client: Client,
    ) => {
      setClientToDelete(client);
    },
    [],
  );
  const handleFormDeleteSubmit = useCallback(async () => {
    try {
      if (clientToDelete) {
        const docRef = firestore.collection('clients').doc(clientToDelete.id);
        await docRef.delete();

        setClientToDelete(null);
        addToast({
          title: 'Cliente excluido',
          message: 'Cliente excluido com sucesso',
          type: 'succes',
        });
      }
    } catch (err) {
      addToast({
        title: 'Erro ao excluir',
        message: 'Ocorreu um erro ao tentar excluir o cliente',
        type: 'error',
      });
    }
  }, [clientToDelete, addToast]);

  const handleDeleteIputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.toLowerCase() === 'delete') {
        setDeleteState(true);
      }
    },
    [],
  );

  useEffect(() => {
    firestore
      .collection('clients')
      .get()
      .then(response => {
        response.forEach(doc => {
          const clientToAdd = {
            id: doc.id,
            name: doc.data().name,
            sites: doc.data().sites,
          };
          setClients(state => [...state, clientToAdd]);
        });
        setLoading(false);
      });
  }, []);

  return (
    <Container>
      {loading ? (
        <LoadingContainer>
          <ReactLoading type="spin" color="#00C9C4" height={50} width={50} />
        </LoadingContainer>
      ) : (
        <>
          <Header>
            <div>
              <button
                type="button"
                onClick={() => {
                  signOut();
                }}
              >
                <FiPower size={24} />
              </button>
            </div>
            <Button
              type="button"
              onClick={() => history.push('/dashboard/clientes/adicionar')}
            >
              <FiPlus size={20} />
              <span>adicionar cliente</span>
            </Button>
          </Header>
          <Content>
            {clients.length > 0 ? (
              <div>
                <ul>
                  {clients.map(client => {
                    return (
                      <li>
                        {`${client.name} ${
                          window.innerWidth > 450
                            ? `(possui ${client.sites.length} site(s) cadastrados)`
                            : ``
                        } `}
                        <div>
                          <button
                            type="button"
                            onClick={
                              () =>
                                history.push(
                                  `/dashboard/clientes/editar/${client.id}`,
                                )
                              // eslint-disable-next-line react/jsx-curly-newline
                            }
                          >
                            <FiEdit2 size={20} />
                          </button>
                          <button
                            type="button"
                            onClick={e => handleClientDeleteAction(e, client)}
                          >
                            <FiTrash2 size={20} color="#9C1B1B" />
                          </button>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : (
              <div>
                <img src={emptyImg} alt="Não possui clientes cadastrado(s)" />
                <strong>Oooops...</strong>
                <span>ainda não foram cadastrados clientes</span>
              </div>
            )}
          </Content>

          {clientToDelete && (
            <DeleteModal>
              <div>
                <DeleteModalHeader>
                  <div>
                    <FiAlertTriangle size={24} />
                    <strong>Excluir este cliente?</strong>
                  </div>
                  <span>
                    Esta ação excluirá permanentemente os dados do cliente
                    <strong>{` ${clientToDelete.name}`}</strong>
                  </span>
                </DeleteModalHeader>
                <DeleteModalContent>
                  <span>
                    Confirme a exclusão deste cliente digitando
                    <strong> DELETE</strong>
                  </span>

                  <Form onSubmit={handleFormDeleteSubmit}>
                    <Input
                      onChange={handleDeleteIputChange}
                      name="delete"
                      placeholder="DELETE"
                    />
                    <section>
                      <Button type="button" onClick={handleFormDeleteCancel}>
                        Cancelar
                      </Button>
                      <Button
                        color="#9c1b1b"
                        type="submit"
                        disabled={!deleteState}
                      >
                        Confirmar
                      </Button>
                    </section>
                  </Form>
                </DeleteModalContent>
              </div>
            </DeleteModal>
          )}
        </>
      )}
    </Container>
  );
};

export default Dashboard;
