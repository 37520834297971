import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip';

interface ContainerProps {
  hasValue?: boolean;
  isFocused?: boolean;
  hasError?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  background-color: #0a223a;
  padding: 8px;
  width: 320px;
  height: 58px;
  border-radius: 8px;
  border: 2px solid #0a223a;
  color: #133f6b;

  ${props =>
    props.hasError &&
    css`
      border-color: #f00;
      color: #f00;
    `}
  ${props =>
    props.hasValue &&
    css`
      color: #00c9c4;
    `}
  ${props =>
    props.isFocused &&
    css`
      border-color: #00c9c4;
      color: #00c9c4;
    `}

    svg {
    margin-right: 8px;
  }
  input {
    color: #b3f7f5;
    display: flex;
    flex: 1;
    background-color: transparent;
    height: 100%;
    &::placeholder {
      color: #6c9695;
    }
  }

  & + div {
    margin-top: 16px;
  }

  @media (max-width: 320px) {
    width: 280px;
  }
`;

export const Error = styled(Tooltip)`
  margin-left: 16px;

  span {
    background-color: #f00;
    color: #fff;

    &::before {
      border-color: #f00 transparent;
    }
  }
  svg {
    margin: 0;
  }
`;
