import React from 'react';

import { useTransition } from 'react-spring';
import { Container } from './styles';
import Toast from './Toast';
import { ToastProps } from '../../hooks/Toast';

interface ToastContainerProps {
  toasts: ToastProps[];
}

const ToastContainer: React.FC<ToastContainerProps> = ({ toasts }) => {
  const toastsAnimeted = useTransition(toasts, toast => toast.id, {
    from: { right: '-120%', opacity: 0 },
    enter: { right: '0', opacity: 1 },
    leave: { right: '-120%', opacity: 0 },
  });

  return (
    <Container>
      {toastsAnimeted.map(({ item, props }) => (
        <Toast
          key={item.id}
          style={props}
          id={item.id}
          type={item.type}
          message={item.message}
          title={item.title}
        />
      ))}
    </Container>
  );
};

export default ToastContainer;
