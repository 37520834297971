import React, { useCallback, useEffect } from 'react';
import {
  FiAlertCircle,
  FiAlertTriangle,
  FiCheckCircle,
  FiXCircle,
} from 'react-icons/fi';
import { clearTimeout } from 'timers';
import { Container } from './styles';

import { ToastProps, useToast } from '../../../hooks/Toast';

interface ContainerToastProps extends ToastProps {
  style: object;
}

const toastIconTypes = {
  error: <FiAlertTriangle size={30} />,
  succes: <FiCheckCircle size={30} />,
  info: <FiAlertCircle size={30} />,
};

const Toast: React.FC<ContainerToastProps> = ({
  title,
  type,
  id,
  message,
  style,
}) => {
  const { removeToast } = useToast();

  useEffect(() => {
    const timeout = setTimeout(() => removeToast(id), 3000);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [removeToast, id]);

  return (
    <Container style={style} type={type} hasDescription={!!message}>
      {toastIconTypes[type || 'info']}
      <div>
        <strong>{title}</strong>
        {message && <p>{message}</p>}
      </div>
      <button
        onClick={() => {
          removeToast(id);
        }}
        type="button"
      >
        <FiXCircle size={15} />
      </button>
    </Container>
  );
};

export default Toast;
