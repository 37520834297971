import { ValidationError } from 'yup';

interface Errors {
  [MediaKeySystemAccess: string]: string;
}

export default function getValidationsErrors(err: ValidationError): Errors {
  const validationsErrors: Errors = {};

  err.inner.forEach(error => {
    validationsErrors[error.path!] = error.message;
  });

  return validationsErrors;
}
