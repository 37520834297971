import React, { createContext, useCallback, useContext, useState } from 'react';
import { uuid } from 'uuidv4';
import ToastContainer from '../components/ToastContainer';

interface ToastContextData {
  addToast(toast: Omit<ToastProps, 'id'>): void;
  removeToast(toastId: string): void;
}

export interface ToastProps {
  id: string;
  type?: 'succes' | 'error' | 'info';
  title: string;
  message?: string;
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData);

export const ToastProvider: React.FC = ({ children }) => {
  const [toasts, setToasts] = useState<ToastProps[]>([]);

  const addToast = useCallback((toast: Omit<ToastProps, 'id'>) => {
    const id = uuid();

    const toastToAdd = {
      id,
      type: toast.type,
      title: toast.title,
      message: toast.message,
    };

    setToasts(oldToasts => [...oldToasts, toastToAdd]);
  }, []);
  const removeToast = useCallback((toastId: string) => {
    setToasts(state => state.filter(oldToasts => oldToasts.id !== toastId));
  }, []);

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      <ToastContainer toasts={toasts} />
    </ToastContext.Provider>
  );
};

export function useToast(): ToastContextData {
  const context = useContext(ToastContext);
  if (!context) {
    throw Error('useToast error');
  }
  return context;
}
