import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

interface ContainerProps {
  type?: 'succes' | 'error' | 'info';
  hasDescription?: boolean;
}

const toastTypes = {
  error: css`
    color: #ffff;
    background: #c90000;
  `,
  succes: css`
    color: #ffff;
    background: #00c92b;
  `,
  info: css`
    background: #00c9c4;
    color: #ffff;
  `,
};

export const Container = styled(animated.div)<ContainerProps>`
  padding: 16px;
  display: flex;
  border-radius: 8px;
  max-width: 360px;
  position: relative;

  ${props => toastTypes[props.type || 'info']}

  + div {
    margin-top: 8px;
  }
  div {
    margin: 0 12px;
    font-size: 14px;
    flex: 1;

    p {
      opacity: 0.75;
    }
  }
  > svg {
    margin: auto 0;
  }
  button {
    background: transparent;
    height: 100%;
    color: inherit;
  }

  ${props =>
    !props.hasDescription &&
    css`
      div {
        margin: auto 12px;
      }
      button {
        margin: auto 0;
      }
    `}
`;
