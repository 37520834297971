import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  span {
    width: 112px;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    bottom: calc(100% + 12px);
    background: #00c9c4;
    font-size: 12px;
    color: #fff;
    font-weight: 500;
    padding: 8px;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 0.4s;

    &::before {
      content: '';
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #00c9c4;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
    }

    @media (max-width: 768px) {
      &::before {
        top: 99%;
      }
    }

    @media (max-width: 350px) {
      left: -50%;

      &::before {
        border: 0;
      }
    }
  }

  &:hover span {
    opacity: 1;
    visibility: visible;
  }
`;
