import styled from 'styled-components';
import div100vh from 'react-div-100vh';

export const Container = styled(div100vh)`
  background-color: #0c2a46;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  form {
    display: flex;
    flex-direction: column;

    button {
      margin-top: 24px;
    }
  }

  img {
    width: 180px;
    margin-bottom: 48px;
  }
`;
