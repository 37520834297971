import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background-color: #0a223a;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.25);
  position: relative;
  height: 68px;

  img {
    width: 160px;
  }

  svg {
    color: #ffff;
  }
`;
