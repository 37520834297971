import React from 'react';
import { Switch } from 'react-router-dom';

import AddClient from '../pages/AddClient';
import Dashboard from '../pages/Dashboard';
import EditClient from '../pages/EditClient';
import SignIn from '../pages/SignIn';
import Route from './Route';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/dashboard" exact component={Dashboard} isPrivate />
      <Route
        path="/dashboard/clientes/adicionar"
        component={AddClient}
        isPrivate
      />
      <Route
        path="/dashboard/clientes/editar/:id"
        component={EditClient}
        isPrivate
      />
    </Switch>
  );
};

export default Routes;
