import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiArrowLeft, FiTrash2, FiAlertTriangle } from 'react-icons/fi';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import ReactLoading from 'react-loading';

import { Form } from '@unform/web';

import Button from '../../components/Button';
import Header from '../../components/Header';
import Input from '../../components/Input';

import {
  Container,
  Content,
  DeleteModal,
  DeleteModalHeader,
  DeleteModalContent,
  LoadingContainer,
} from './styles';
import { firestore } from '../../firebase/firebase';
import Client from '../../types/Client';
import { useToast } from '../../hooks/Toast';
import getValidationsErrors from '../../utils/getValitionsErrors';

interface ClientsParams {
  id: string;
}
interface FormData {
  clientName: string;
  clientSites: string[];
}

const EditClient: React.FC = () => {
  const [siteInputs, setSiteInputs] = useState<number[]>([]);
  const [client, setClient] = useState<Client>();
  const [deleteState, setDeleteState] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [hasSite, setHasSite] = useState(false);
  const [loading, setLoading] = useState(true);
  const formRef = useRef<FormHandles>(null);
  const { params } = useRouteMatch<ClientsParams>();
  const history = useHistory();
  const { addToast } = useToast();

  const handleRemoveSiteInput = useCallback(
    inputToRemove => {
      const newSiteInputs = siteInputs.filter(input => {
        return input !== inputToRemove;
      });
      setSiteInputs(newSiteInputs);
    },
    [siteInputs],
  );

  const handleAddSiteInput = useCallback(() => {
    const newSite = siteInputs.length;
    setSiteInputs([...siteInputs, newSite]);
  }, [siteInputs]);

  const handleNewSiteInput = useCallback(() => {
    setHasSite(true);
    const newSite = siteInputs.length;
    setSiteInputs(state => [...state, newSite]);
  }, [siteInputs.length]);

  const handleFormSubmit = useCallback(
    async (data: FormData) => {
      try {
        const formSchema = Yup.object().shape({
          clientName: Yup.string().required('Nome do cliente obrigatório'),
          clientSites: Yup.array().of(
            Yup.string()
              .required('Site obrigatório')
              .url('Precisa ser do tipo "URL"'),
          ),
        });

        await formSchema.validate(data, { abortEarly: false });
        await firestore
          .collection('clients')
          .doc(client?.id)
          .update({ name: data.clientName, sites: data.clientSites });

        history.push('/dashboard');
        addToast({
          title: 'Cliente atualizado',
          message: 'Cliente atualizado com sucesso',
          type: 'succes',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          formRef.current?.setErrors(getValidationsErrors(err));
          return;
        }

        addToast({
          title: 'Erro ao salvar',
          message: 'Erro ao salvar cliente',
          type: 'error',
        });
        console.log(err);
      }
    },
    [addToast, client, history],
  );

  useEffect(() => {
    const docRef = firestore.collection('clients').doc(params.id);
    docRef.get().then(doc => {
      if (doc.exists) {
        const clientToSave = {
          id: doc.id,
          name: doc.data()?.name,
          sites: doc.data()?.sites,
        };
        setClient(clientToSave);
      }
      setLoading(false);
    });
  }, [params]);

  useEffect(() => {
    if (client) {
      if (client.sites.length >= 1) {
        setHasSite(true);
        client.sites.forEach((value, index) => {
          setSiteInputs(state => [...state, index]);
        });
      }
    }
  }, [client]);

  const handleDeleteIputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.toLowerCase() === 'delete') {
        setDeleteState(true);
      }
    },
    [],
  );

  const handleFormDeleteSubmit = useCallback(async () => {
    try {
      console.log('deletando...');
      if (client) {
        const docRef = firestore.collection('clients').doc(client.id);
        await docRef.delete();

        setModalOpen(false);
        history.push('/dashboard');
        addToast({
          title: 'Cliente excluido',
          message: 'Cliente excluido com sucesso',
          type: 'succes',
        });
      }
    } catch (err) {
      addToast({
        title: 'Erro ao excluir',
        message: 'Ocorreu um erro ao tentar excluir o cliente',
        type: 'error',
      });
    }
  }, [addToast, client]);

  return (
    <Container>
      {loading ? (
        <LoadingContainer>
          <ReactLoading type="spin" color="#00C9C4" height={50} width={50} />
        </LoadingContainer>
      ) : (
        <>
          <Header>
            <Link to="/dashboard">
              <FiArrowLeft size={24} />
            </Link>
          </Header>

          <Content>
            {client && (
              <Form ref={formRef} onSubmit={handleFormSubmit}>
                <Input
                  name="clientName"
                  placeholder="Nome do cliente"
                  defaultValue={client.name}
                />
                <div>
                  {hasSite ? (
                    <>
                      <strong>Site(s):</strong>
                      {siteInputs.map(input => (
                        <div>
                          <Input
                            name={`clientSites[${input}]`}
                            placeholder="Site do cliente"
                            defaultValue={client.sites[input]}
                          />
                          <button
                            type="button"
                            onClick={() => handleRemoveSiteInput(input)}
                          >
                            <FiTrash2 size={24} />
                          </button>
                        </div>
                      ))}

                      <button type="button" onClick={handleAddSiteInput}>
                        Adicionar mais site
                      </button>
                    </>
                  ) : (
                    <>
                      <span>Sem sites cadastrados</span>
                      <button type="button" onClick={handleNewSiteInput}>
                        Adicionar novo site
                      </button>
                    </>
                  )}
                </div>

                <Button type="submit">Salvar cliente</Button>

                <Button
                  type="button"
                  onClick={() => setModalOpen(true)}
                  color="#9C1B1B"
                >
                  <FiTrash2 />
                  Excluir cliente
                </Button>
              </Form>
            )}
          </Content>
          {modalOpen && (
            <DeleteModal>
              <div>
                <DeleteModalHeader>
                  <div>
                    <FiAlertTriangle size={24} />
                    <strong>Excluir este cliente?</strong>
                  </div>
                  <span>
                    Esta ação excluirá permanentemente os dados do cliente
                    <strong>{` ${client?.name}`}</strong>
                  </span>
                </DeleteModalHeader>
                <DeleteModalContent>
                  <span>
                    Confirme a exclusão deste cliente digitando
                    <strong> DELETE</strong>
                  </span>

                  <Form onSubmit={handleFormDeleteSubmit}>
                    <Input
                      onChange={handleDeleteIputChange}
                      name="delete"
                      placeholder="DELETE"
                    />
                    <section>
                      <Button type="button" onClick={() => setModalOpen(false)}>
                        Cancelar
                      </Button>
                      <Button
                        color="#9c1b1b"
                        type="submit"
                        disabled={!deleteState}
                      >
                        Confirmar
                      </Button>
                    </section>
                  </Form>
                </DeleteModalContent>
              </div>
            </DeleteModal>
          )}
        </>
      )}
    </Container>
  );
};

export default EditClient;
