import React, { useCallback, useRef, useState, useEffect } from 'react';
import { FiArrowLeft, FiTrash2 } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import ReactLoading from 'react-loading';
import * as Yup from 'yup';
import { uuid } from 'uuidv4';

import Button from '../../components/Button';
import Header from '../../components/Header';
import Input from '../../components/Input';
import { Container, Content, LoadingContainer } from './styles';
import { firestore } from '../../firebase/firebase';
import getValidationsErrors from '../../utils/getValitionsErrors';
import { useToast } from '../../hooks/Toast';

interface FormData {
  clientName: string;
  clientSites: string[];
}

const AddClient: React.FC = () => {
  const [siteInputs, setSiteInputs] = useState([0]);
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const handleRemoveSiteInput = useCallback(
    inputToRemove => {
      const newSiteInputs = siteInputs.filter(input => {
        return input !== inputToRemove;
      });
      setSiteInputs(newSiteInputs);
    },
    [siteInputs],
  );

  const handleNewSiteInput = useCallback(() => {
    const newSite = siteInputs.length;
    setSiteInputs(state => [...state, newSite]);
  }, [siteInputs.length]);

  const handleAddSiteInput = useCallback(() => {
    const newSite = siteInputs.length;
    setSiteInputs(state => [...state, newSite]);
  }, []);

  useEffect(() => {
    setLoading(false);
  }, []);

  const handleFormSubmit = useCallback(
    async (data: FormData) => {
      try {
        const formSchema = Yup.object().shape({
          clientName: Yup.string().required('Nome do cliente obrigatório'),
          clientSites: Yup.array().of(
            Yup.string()
              .required('Site obrigatório')
              .url('Precisa ser do tipo "URL"'),
          ),
        });

        await formSchema.validate(data, { abortEarly: false });
        await firestore
          .collection('clients')
          .doc(uuid())
          .set({ name: data.clientName, sites: data.clientSites });

        history.push('/dashboard');
        addToast({
          title: 'Cliente salvo',
          message: 'Cliente salvo com sucesso',
          type: 'succes',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          formRef.current?.setErrors(getValidationsErrors(err));
          return;
        }

        addToast({
          title: 'Erro ao salvar',
          message: 'Erro ao salvar cliente',
          type: 'error',
        });
      }
    },
    [addToast, history],
  );

  return (
    <Container>
      {loading ? (
        <LoadingContainer>
          <ReactLoading type="spin" color="#00C9C4" height={50} width={50} />
        </LoadingContainer>
      ) : (
        <>
          <Header>
            <Link to="/dashboard">
              <FiArrowLeft size={24} />
            </Link>
          </Header>

          <Content>
            <Form ref={formRef} onSubmit={handleFormSubmit}>
              <Input name="clientName" placeholder="Nome do cliente" />

              <div>
                {siteInputs.length >= 1 ? (
                  <>
                    <strong>Site(s):</strong>
                    {siteInputs.map(input => (
                      <div>
                        <Input
                          name={`clientSites[${input}]`}
                          placeholder="Site do cliente (https://site.com)"
                        />
                        <button
                          type="button"
                          onClick={() => handleRemoveSiteInput(input)}
                        >
                          <FiTrash2 size={24} />
                        </button>
                      </div>
                    ))}

                    <button type="button" onClick={handleAddSiteInput}>
                      Adicionar mais site
                    </button>
                  </>
                ) : (
                  <>
                    <span>Sem sites cadastrados</span>
                    <button type="button" onClick={handleNewSiteInput}>
                      Adicionar novo site
                    </button>
                  </>
                )}
              </div>

              <Button type="submit">Salvar cliente</Button>
            </Form>
          </Content>
        </>
      )}
    </Container>
  );
};

export default AddClient;
