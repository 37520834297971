import { shade } from 'polished';
import { use100vh } from 'react-div-100vh';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: #0c2a46;
  min-height: ${use100vh}px;
  overflow: auto;
`;

export const Content = styled.div`
  max-width: 1024px;
  margin: 40px auto;

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > div {
      margin-top: 36px;
      button {
        background: transparent;
        color: #00c9c4;
        margin-bottom: 24px;
        float: right;
        font-weight: bold;
      }

      strong {
        display: block;
        color: #ffff;
        font-size: 16px;
        margin-bottom: 8px;
      }

      span {
        display: block;
        color: #fff;
        font-weight: bold;
        font-size: 16px;
        width: 320px;
      }

      @media (max-width: 350px) {
        span {
          width: 280px;
        }
      }

      > div {
        width: 320px;
        display: flex;
        div {
          max-width: 250px;
        }
        @media (max-width: 350px) {
          width: 280px;
          div {
            max-width: 220px;
          }
        }
        & + div {
          margin-top: 8px;
        }
        button {
          background-color: #8c2a1a;
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          color: #fff;
          height: 58px;
          margin-bottom: 0;
          margin-left: 10px;

          &:hover {
            background-color: ${shade(0.2, '#8c2a1a')};
          }
        }
      }
    }
  }
`;

export const DeleteModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);

  > div {
    margin: 10% auto;
    width: 50%;
    @media (max-width: 1024px) {
      width: 85%;
    }
  }
`;

export const DeleteModalHeader = styled.div`
  background-color: #9c1b1b;
  color: #ffff;
  border-radius: 16px 16px 0 0;
  padding: 20px;

  > span {
    margin-top: 20px;
    display: block;
  }
  > div {
    display: flex;
    align-items: center;

    strong {
      margin-left: 10px;
      font-size: 20px;
    }
  }
`;

export const DeleteModalContent = styled.div`
  background-color: #0c2a46;
  padding: 20px;
  color: #fff;
  border-radius: 0 0 16px 16px;

  form {
    margin-top: 20px;

    div {
      width: 100%;
    }

    section {
      display: flex;
      margin-top: 30px;
      justify-content: flex-end;
      button {
        width: 120px;
        float: right;

        & + button {
          margin-top: 0;
          margin-left: 15px;
        }
      }
    }
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${use100vh}px;
`;
